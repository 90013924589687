import { useGoogleLogin } from "@react-oauth/google";
import {
  AppleLightIcon,
  GoogleLightIcon,
  LinkedinLightIcon,
  MailIcon,
} from "icons";
import { useRouter } from "next/navigation";
import { Button as AriaButton } from "react-aria-components";
import { Heading, Separator, Text, ImageWrapper } from "ui-components";

import { useNavigate } from "@/src/hooks/useNavigate";

export interface IHeroSectionProps {
  buttonInitials?: "Log in with" | "Join with";
}

export function HeroSection({
  buttonInitials = "Join with",
}: IHeroSectionProps): JSX.Element {
  return (
    <div className="w-full flex gap-x-20 justify-center lg:justify-between">
      <div className="w-full max-w-[500px] lg:w-1/2 flex flex-col justify-center items-center text-center lg:justify-center lg:items-center lg:text-left gap-y-5 lg:gap-y-[30px]">
        <div className="flex flex-col gap-y-5">
          <Heading className="text-center lg:text-left">
            Welcome to the myGwork LGBT+ Business Community!
          </Heading>
          <Text className="text-center lg:text-left" variant="large">
            myGwork is the Global Networking Hub and Job board for LGBTQ+
            Professionals & Students.
          </Text>
        </div>
        <div className="flex flex-col gap-y-2.5 lg:gap-y-[15px] w-full text-sm">
          <SocialLoginButton type="linked-in" buttonInitials={buttonInitials} />
          <SocialLoginButton type="google" buttonInitials={buttonInitials} />
          <SocialLoginButton type="apple" buttonInitials={buttonInitials} />
          <Separator text="or" />
          <SocialLoginButton type="email" buttonInitials={buttonInitials} />
          <Text
            variant="small"
            align="center"
            className="hidden lg:block -mt-[5px]"
          >
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </Text>
        </div>
      </div>
      <div className="hidden lg:flex justify-center items-center">
        <ImageWrapper
          src="/homepage/new-hero-image.svg"
          width={688}
          height={486}
          alt="hero-illustration"
          className="object-contain w-[688px] h-[486px] rounded-large"
        />
      </div>
    </div>
  );
}

interface ISocialLoginButton {
  type: "linked-in" | "google" | "apple" | "email";
  buttonInitials?: "Log in with" | "Join with";
}

export function SocialLoginButton({
  type,
  buttonInitials = "Join with",
}: ISocialLoginButton): JSX.Element | null {
  const router = useRouter();
  const { push } = useNavigate();

  function generateState(): string {
    return [...Array(30)].map(() => Math.random().toString(36)[2]).join("");
  }
  const handleLinkedInSignIn = (): void => {
    const state = generateState();
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.LINKEDIN_REDIRECT_URI}&state=${state}&scope=openid%20profile%20email`;
    router.replace(authUrl);
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const newUrl = `/signin?token=${tokenResponse.access_token}&provider=google`;
      window.location.href = newUrl;
    },
  });

  const handleAppleSignIn = (): void => {
    const clientId = "MyGwork-v2";
    const redirectURI = encodeURIComponent(
      `${process.env.API_URL}/auth/oauth/apple/redirect`
    );
    const scope = encodeURIComponent("name email");
    const responseType = "code";
    const responseMode = "form_post";

    window.location.href = `https://appleid.apple.com/auth/authorize?response_type=${responseType}&response_mode=${responseMode}&client_id=${clientId}&redirect_uri=${redirectURI}&scope=${scope}`;
  };

  switch (type) {
    case "linked-in":
      return (
        <AriaButton
          className="bg-[#0077B5] w-full rounded-full text-white h-[50px] relative text-sm"
          onPress={handleLinkedInSignIn}
        >
          <LinkedinLightIcon className="w-10 h-10 absolute left-1.5 top-[5px]" />
          {buttonInitials} LinkedIn
        </AriaButton>
      );
    case "google":
      return (
        <AriaButton
          className="bg-[#1A73E8] w-full rounded-full text-white h-[50px] relative text-sm"
          onPress={() => handleGoogleSignIn()}
        >
          <GoogleLightIcon className="w-10 h-10 absolute left-1.5 top-[5px]" />
          {buttonInitials} Google
        </AriaButton>
      );
    case "apple":
      return (
        <AriaButton
          className="bg-black w-full rounded-full text-white h-[50px] flex items-center justify-center text-sm"
          onPress={() => handleAppleSignIn()}
        >
          <AppleLightIcon className="w-4 h-4 mr-2" />
          {buttonInitials} Apple
        </AriaButton>
      );
    case "email":
      return (
        <AriaButton
          className="bg-primary w-full rounded-full h-[50px] relative text-sm text-black"
          onPress={() => push("/signup")}
        >
          <div className="bg-white absolute left-1.5 top-[5px] rounded-full w-10 h-10 flex items-center justify-center">
            <MailIcon className="w-5 h-5" />
          </div>
          Join with email
        </AriaButton>
      );
    default:
      return null;
  }
}

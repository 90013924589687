import { cva, type VariantProps } from "class-variance-authority";
import { CloseIcon } from "icons";
import { ReactNode } from "react";
import { Button } from "react-aria-components";
import toast from "react-hot-toast";

export interface IMakeToast extends VariantProps<typeof toastClasses> {
  title: ReactNode;
}
export interface IMakeToastPromise extends VariantProps<typeof toastClasses> {
  loadingTitle: string;
  successTitle: string;
  errorTitle: string;
  fn: () => Promise<unknown>;
}

export function makeToast({ title, intent }: IMakeToast): unknown {
  return toast((t) => (
    <div className={toastClasses({ intent })}>
      <span className="text-center">{title}</span>
      <Button onPress={() => toast.dismiss(t.id)}>
        <CloseIcon className="w-4 h-4" />
      </Button>
    </div>
  ));
}

export function makeToastPromise({
  loadingTitle,
  successTitle,
  errorTitle,
  fn,
}: IMakeToastPromise): unknown {
  return toast.promise(fn(), {
    loading: <b className="p-4">{loadingTitle}</b>,
    success: <b className="p-4">{successTitle}</b>,
    error: <b className="p-4">{errorTitle}</b>,
  });
}

const toastClasses = cva("flex items-center justify-center gap-4 p-4 w-96", {
  variants: {
    intent: {
      white: ["bg-white"],
      info: ["bg-brand-blue text-white"],
      danger: ["bg-brand-danger text-white"],
      success: ["bg-brand-success text-white"],
      warning: ["bg-brand-warning"],
    },
  },
  defaultVariants: {
    intent: "white",
  },
});

import { CloseIcon } from "icons";
import React, { useState } from "react";
import { Button } from "react-aria-components";
import { LinkButton, Text } from "ui-components";

export function StickyCta(): JSX.Element {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div
      className={`hidden ${
        isVisible ? "md:block" : ""
      } rounded-[30px] fixed bottom-5 right-5 p-5 bg-white max-w-[280px] shadow-2xl`}
    >
      <div className="flex flex-col gap-y-5 relative items-center">
        <Button
          onPress={() => setIsVisible(false)}
          className="absolute top-0 right-0 outline-none z-50"
        >
          <CloseIcon className="w-4 h-4" />
        </Button>
        <Text align="center" variant="large">
          Interested in joining or advertising with us?
        </Text>
        <LinkButton href="/about-us/corporate-solutions">
          Learn about our Services
        </LinkButton>
        <LinkButton href="/about-us/contact-us">Contact us</LinkButton>
      </div>
    </div>
  );
}

export function VideoSection(): JSX.Element {
  return (
    <div className="video-container">
      {/* TODO: replace with proper MGW video */}
      <iframe
        className="w-full h-[175px] md:h-[600px] border border-primary rounded-2xl"
        src="https://www.youtube.com/embed/BjeyC7tK8To"
        title="We are myGwork"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
}

"use client";

import { Heading, LinkButton, ImageWrapper, Text } from "ui-components";

import { OUR_PARTNER_ORGANIZATIONS_SLIDER_SETTINGS } from "@/src/constants/HomePage";
import { useOrganizationGetOrganizationMetaData } from "@/src/hooks/api/generated";

import { Slider } from "../slider/Slider";

export function OurPartnerOrganizations(): JSX.Element {
  const { data: organisationMetaData } =
    useOrganizationGetOrganizationMetaData();

  return (
    <div className="flex flex-col gap-y-10">
      <Heading variant="h2" align="center">
        They work with us
      </Heading>
      <div>
        <Slider settings={OUR_PARTNER_ORGANIZATIONS_SLIDER_SETTINGS}>
          {organisationMetaData?.featuredOrganizations.map((organisation) => {
            const logoImage = organisation.organizationImage.find(
              (img) => img?.image?.type === "logo"
            )?.image?.public_id;
            if (logoImage) {
              return (
                <div className="!flex justify-center" key={organisation.name}>
                  <ImageWrapper
                    src={logoImage}
                    placeholderUrl=""
                    width={100}
                    height={100}
                    className="w-20 h-20 lg:w-24 lg:h-24"
                    alt={organisation.name}
                    isCLDImage
                  />
                </div>
              );
            }
            return (
              <div className="!flex justify-center" key={organisation.name}>
                <div className="w-20 h-20 lg:w-24 lg:h-24 bg-black flex items-center justify-center px-2">
                  <Text
                    intent="white"
                    className="capitalize line-clamp-2"
                    align="center"
                  >
                    {organisation.name}
                  </Text>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <LinkButton href="/organizations" className="mx-auto">
        View All Organizations
      </LinkButton>
    </div>
  );
}

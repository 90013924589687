"use client";

import React, { FC } from "react";

import { useNavigate } from "@/hooks/useNavigate";

import { CookieKeys } from "../enums/CookieKeys.enum";
import { useAuth } from "../hooks/useAuth";
import { useCookies } from "../hooks/useCookies";

export const withPublic = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<P> => {
  const RequiresAuth: FC<P> = (props) => {
    const { push } = useNavigate();
    const { isAuthenticated, authData } = useAuth();
    const { getCookie } = useCookies();
    // const searchParams = useSearchParams();
    const searchParams = new URLSearchParams(
      typeof window !== "undefined" ? window.location.search : ""
    );
    const redirectTo = searchParams.get("redirect-to") ?? undefined;
    const jobSlug = getCookie(CookieKeys.JobSlug);

    if (isAuthenticated) {
      if (jobSlug) {
        push(`/jobs/${jobSlug}`);
      } else if (redirectTo) {
        push(redirectTo);
      } else {
        push(
          authData?.isLegacyOrganizationAdmin
            ? "/profile/organization-profile"
            : "/profile/home"
        );
      }
    }

    return <WrappedComponent {...props} />;
  };

  return RequiresAuth;
};

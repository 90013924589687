import {
  CalendarMultilineIcon,
  ExpandProfessionalNetworkIcon,
  HandsIcon,
  MentorIcon,
} from "icons";
import { Heading, Text } from "ui-components";

export function WhatWeOffer(): JSX.Element {
  return (
    <div className="bg-primary bg-opacity-[10%] rounded-2xl p-10 text-center">
      <Heading align="center" variant="h2" className="mb-10">
        What we offer to our members
      </Heading>
      <div className="flex flex-wrap justify-between items-start gap-y-5">
        <div className="flex flex-col items-center gap-y-5 w-full lg:w-[22%]">
          <div className="lg:w-[105px] lg:h-[105px] flex items-center justify-center">
            <ExpandProfessionalNetworkIcon className="w-14" />
          </div>
          <Heading
            align="center"
            variant="h3"
            className="lg:min-h-[7rem] xl:min-h-[5rem]"
          >
            Expand Your Professional Network
          </Heading>
          <Text align="center">
            Join the first LGBTQ+ and allies’ professional community, make new
            friends and connect with your colleagues around the world.
          </Text>
        </div>
        <div className="flex flex-col items-center gap-y-5 w-full lg:w-[22%]">
          <div className="lg:w-[105px] lg:h-[105px] flex items-center justify-center">
            <HandsIcon className="w-14" />
          </div>
          <Heading
            align="center"
            variant="h3"
            className="lg:min-h-[7rem] xl:min-h-[5rem]"
          >
            Connect With Our Inclusive Corporate Partners
          </Heading>
          <Text align="center">
            Search jobs in LGBTQ+-inclusive organizations and start your new
            professional challenge.
          </Text>
        </div>
        <div className="flex flex-col items-center gap-y-5 w-full lg:w-[22%]">
          <div className="lg:w-[105px] lg:h-[105px] flex items-center justify-center">
            <CalendarMultilineIcon className="w-14" />
          </div>
          <Heading
            align="center"
            variant="h3"
            className="lg:min-h-[7rem] xl:min-h-[5rem]"
          >
            Browse Events and News
          </Heading>
          <Text align="center">
            Look for hundreds of global events and stay up to date with news
            affecting the LGBTQ+ community.
          </Text>
        </div>
        <div className="flex flex-col items-center gap-y-5 w-full lg:w-[22%]">
          <div className="lg:w-[105px] lg:h-[105px] flex items-center justify-center">
            <MentorIcon className="w-14" />
          </div>
          <Heading
            align="center"
            variant="h3"
            className="lg:min-h-[7rem] xl:min-h-[5rem]"
          >
            Become or Find A Mentor
          </Heading>
          <Text align="center">
            Browse thousands of LGBTQ+ mentors and allies in your industry or
            give back to the community and let students and other professionals
            contact you for guidance and advice.
          </Text>
        </div>
      </div>
    </div>
  );
}
